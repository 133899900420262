import styled from "../../../service/theme/styled-components";
import { PageContent } from "../page.css";

export const PlacesRanking = styled(PageContent)``;

export const PlacesPrompt = styled.span``;

export const PlacesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const PlacesListItem = styled.li`
  margin: 0;
`;
