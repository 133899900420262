import config from "../../config";

interface IParams {
  [key: string]: string;
}
interface IOption {
  value: string;
  label: string;
}

type IOptions = IOption[];

export function selectify(block: IParams): IOptions {
  const options: IOptions = Object.keys(block).map(key => {
    return { value: key, label: block[key] };
  });
  return options;
}

export function percentage2color(percentage: number) {
  const colors = config.map.foursquare.colors;
  return colors[Math.round((percentage * (colors.length - 1)) / 100)];
}

// adapted from https://gist.github.com/mlocati/7210513
export const hexToRGBA = (hex: string, alpha: number) => {
  hex = hex.charAt(0) === "#" ? hex.substring(1, 7) : hex;

  if (hex.length !== 6) {
    alert("Invalid length of the input hex value!");
    return;
  }
  if (/[0-9a-f]{6}/i.test(hex) !== true) {
    alert("Invalid digits in the input hex value!");
    return;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b, alpha * 255];
};

// adapted from https://codepen.io/sosuke/pen/Pjoqqp?editors=0010#0
export class Color {
  public red: number;
  public green: number;
  public blue: number;
  constructor(red: number, green: number, blue: number) {
    this.red = this.clamp(red);
    this.green = this.clamp(green);
    this.blue = this.clamp(blue);
  }

  set(red: number, green: number, blue: number) {
    this.red = this.clamp(red);
    this.green = this.clamp(green);
    this.blue = this.clamp(blue);
  }

  toString() {
    return `rgb(${Math.round(this.red)}, ${Math.round(
      this.green
    )}, ${Math.round(this.blue)})`;
  }

  hueRotate(angle = 0) {
    angle = (angle / 180) * Math.PI;
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072
    ]);
  }

  grayscale(value = 1) {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value)
    ]);
  }

  sepia(value = 1) {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value)
    ]);
  }

  saturate(value = 1) {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value
    ]);
  }

  multiply(matrix: number[]) {
    const newR = this.clamp(
      this.red * matrix[0] + this.green * matrix[1] + this.blue * matrix[2]
    );
    const newG = this.clamp(
      this.red * matrix[3] + this.green * matrix[4] + this.blue * matrix[5]
    );
    const newB = this.clamp(
      this.red * matrix[6] + this.green * matrix[7] + this.blue * matrix[8]
    );
    this.red = newR;
    this.green = newG;
    this.blue = newB;
  }

  brightness(value = 1) {
    this.linear(value);
  }
  contrast(value = 1) {
    this.linear(value, -(0.5 * value) + 0.5);
  }

  linear(slope = 1, intercept = 0) {
    this.red = this.clamp(this.red * slope + intercept * 255);
    this.green = this.clamp(this.green * slope + intercept * 255);
    this.blue = this.clamp(this.blue * slope + intercept * 255);
  }

  invert(value = 1) {
    this.red = this.clamp((value + (this.red / 255) * (1 - 2 * value)) * 255);
    this.green = this.clamp(
      (value + (this.green / 255) * (1 - 2 * value)) * 255
    );
    this.blue = this.clamp((value + (this.blue / 255) * (1 - 2 * value)) * 255);
  }

  hsl() {
    // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
    const r = this.red / 255;
    const g = this.green / 255;
    const b = this.blue / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    let l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;

        case g:
          h = (b - r) / d + 2;
          break;

        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return {
      h: h * 100,
      s: s * 100,
      l: l * 100
    };
  }

  clamp(value: number) {
    if (value > 255) {
      value = 255;
    } else if (value < 0) {
      value = 0;
    }
    return value;
  }
}

export class Solver {
  private target: Color;
  private targetHSL: { h: number; s: number; l: number };
  private redeusedColor: Color;
  constructor(target: Color) {
    this.target = target;
    this.targetHSL = target.hsl();
    this.redeusedColor = new Color(0, 0, 0);
  }

  solve() {
    const result = this.solveNarrow({ values: [], ...this.solveWide() });
    return {
      values: result.values,
      loss: result.loss,
      filter: this.css(result.values || [])
    };
  }

  solveWide() {
    const A = 5;
    const c = 15;
    const a = [60, 180, 18000, 600, 1.2, 1.2];

    let best = { loss: Infinity };
    for (let i = 0; best.loss > 25 && i < 3; i++) {
      const initial = [50, 20, 3750, 50, 100, 100];
      const result = this.spsa(A, a, c, initial, 1000);
      if (result.loss < best.loss) {
        best = result;
      }
    }
    return best;
  }

  solveNarrow(wide: { loss: number; values: number[] }) {
    const A = wide.loss;
    const c = 2;
    const A1 = A + 1;
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
    return this.spsa(A, a, c, wide.values, 500);
  }

  spsa(A: number, a: number[], c: number, values: number[], iters: number) {
    const alpha = 1;
    const gamma = 0.16666666666666666;

    let best = null;
    let bestLoss = Infinity;
    const deltas = new Array(6);
    const highArgs = new Array(6);
    const lowArgs = new Array(6);

    for (let k = 0; k < iters; k++) {
      const ck = c / Math.pow(k + 1, gamma);
      for (let i = 0; i < 6; i++) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1;
        highArgs[i] = values[i] + ck * deltas[i];
        lowArgs[i] = values[i] - ck * deltas[i];
      }

      const lossDiff = this.loss(highArgs) - this.loss(lowArgs);
      for (let i = 0; i < 6; i++) {
        const g = (lossDiff / (2 * ck)) * deltas[i];
        const ak = a[i] / Math.pow(A + k + 1, alpha);
        values[i] = fix(values[i] - ak * g, i);
      }

      const loss = this.loss(values);
      if (loss < bestLoss) {
        best = values.slice(0);
        bestLoss = loss;
      }
    }
    return { values: best, loss: bestLoss };

    function fix(value: number, idx: number) {
      let max = 100;
      if (idx === 2 /* saturate */) {
        max = 7500;
      } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
        max = 200;
      }

      if (idx === 3 /* hue-rotate */) {
        if (value > max) {
          value %= max;
        } else if (value < 0) {
          value = max + (value % max);
        }
      } else if (value < 0) {
        value = 0;
      } else if (value > max) {
        value = max;
      }
      return value;
    }
  }

  loss(filters: number[]) {
    // Argument is array of percentages.
    const color = this.redeusedColor;
    color.set(0, 0, 0);

    color.invert(filters[0] / 100);
    color.sepia(filters[1] / 100);
    color.saturate(filters[2] / 100);
    color.hueRotate(filters[3] * 3.6);
    color.brightness(filters[4] / 100);
    color.contrast(filters[5] / 100);

    const colorHSL = color.hsl();
    return (
      Math.abs(color.red - this.target.red) +
      Math.abs(color.green - this.target.green) +
      Math.abs(color.blue - this.target.blue) +
      Math.abs(colorHSL.h - this.targetHSL.h) +
      Math.abs(colorHSL.s - this.targetHSL.s) +
      Math.abs(colorHSL.l - this.targetHSL.l)
    );
  }

  css(filters: number[]) {
    function fmt(idx: number, multiplier = 1) {
      return Math.round(filters[idx] * multiplier);
    }
    return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(
      2
    )}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(
      5
    )}%)`;
  }
}

export function union<T>(...iterables: Array<Set<T>>): Set<T> {
  const set = new Set<T>();
  iterables.forEach(iterable => {
    iterable.forEach(item => set.add(item));
  });
  return set;
}

export function difference<T>(minuend: Set<T>, subtrahend: Set<T>) {
  const set = new Set<T>(
    Array.from(minuend).filter(item => !subtrahend.has(item))
  );
  return set;
}

export { default as mutations } from "./mutations";
